const siteRoot = [
  'https://console-dev.platforms.nike.com',
  'https://console-nonprod.platforms.nike.com',
  'https://console.platforms.nike.com',
].includes(window.location.origin)
  ? '/map/'
  : '/'

export default {
  slack: 'slack://channel?team=T0G3T5X2B&id=CR37NGSH1',
  siteRoot: siteRoot,
  awsAccountId: '514712878770',
  awsAccountIdChina: '471053898507',
  mapCustomOpsImageVersion: '1.3.8',
  defaultRegion: 'us-west-2',

  // APM distributed tracing
  apmTraceOrigins: [
    'https://apinonprod.us-west-2.preprod.map.nike.com',
    'https://api.us-west-2.map.nike.com',
    'https://api.us-east-1.map.nike.com',
    'https://api.eu-west-1.map.nike.com',
  ],

  // NSP Links, no change between our prod/nonprod
  nspMetrics: (region, cluster_id) =>
    `https://console.platforms.nike.com/nsp/sources/details?resource=map-cluster-metrics-${region}-${cluster_id}`,
  nspLogs: (region, cluster_id) =>
    `https://console.platforms.nike.com/nsp/sources/details?resource=map-dag-logs-${region}-${cluster_id}`,
}
